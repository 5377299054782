// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'
// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
//
//
// business_category/
export default {
  getLocalMetricsKeywordsSearch(after, before, id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/search-keywords-impressions/monthly/`
      let start = after.split('/')
      let end = before.split('/')
      query += `?monthlyRange.start_month.year=${start[2]}&monthlyRange.start_month.month=${start[1]}`
      query += `&monthlyRange.end_month.year=${end[2]}&monthlyRange.end_month.month=${end[1]}`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getLocalMetricsViewsProfile(after, before, id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/custom-multi-daily-metrics-time-series/?dailyMetrics=BUSINESS_IMPRESSIONS_DESKTOP_MAPS&dailyMetrics=BUSINESS_IMPRESSIONS_DESKTOP_SEARCH&dailyMetrics=BUSINESS_IMPRESSIONS_MOBILE_SEARCH&dailyMetrics=BUSINESS_IMPRESSIONS_MOBILE_MAPS`
      let start = after.split('/')
      let end = before.split('/')
      query += `&dailyRange.start_date.year=${start[2]}&dailyRange.start_date.month=${start[1]}&dailyRange.start_date.day=${start[0]}`
      query += `&dailyRange.end_date.year=${end[2]}&dailyRange.end_date.month=${end[1]}&dailyRange.end_date.day=${end[0]}`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getLocalMetricsGoogle(after, before) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/performance_stats_v2/`
      query += `?after=${after}`
      query += `&before=${before}`
      query += `&tree=${Stores.getters.getTreeSelected.id}`
      if(Stores.getters.getNodeLevel === 9){
        if(Stores.getters.getNode){
          query += `&center=${Stores.getters.getNode}`
        }
      }else{
        if(Stores.getters.getNode){
          query += `&node=${Stores.getters.getNode}`
        }
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getLocalMetricsMeta(after, before) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/meta_insights/`
      query += `?after=${after}`
      query += `&before=${before}`
      query += `&tree=${Stores.getters.getTreeSelected.id}`
      if(Stores.getters.getNodeLevel === 9){
        if(Stores.getters.getNode){
          query += `&center=${Stores.getters.getNode}`
        }
      }else{
        if(Stores.getters.getNode){
          query += `&node=${Stores.getters.getNode}`
        }
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getLocalMetricsBrand(after, before, type) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/performance_stats/`
      query += `?dailyMetric=${type}`
      let start = after.split('/')
      let end = before.split('/')
      query += `&dailyRange.start_date.year=${start[2]}&dailyRange.start_date.month=${start[1]}&dailyRange.start_date.day=${start[0]}`
      query += `&dailyRange.end_date.year=${end[2]}&dailyRange.end_date.month=${end[1]}&dailyRange.end_date.day=${end[0]}`
      query += `&tree=${Stores.getters.getTreeSelected.id}`
      if(Stores.getters.getNodeLevel === 9){
        if(Stores.getters.getNode){
          query += `&center=${Stores.getters.getNode}`
        }
      }else{
        if(Stores.getters.getNode){
          query += `&node=${Stores.getters.getNode}`
        }
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
