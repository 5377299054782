<template lang="pug">
  div
    div.margin-bottom-0.gridFull.px-0
      v-row.flex-center.b-bottom.mb-0.no-gutters.main(:style="`top: ${headerHeight}px`")
        v-col(md="2")
          .block-count.pl-4
            span.mr-1.font-weight-med {{opinionCounts}}
            translate Reviews
        v-col(md="10")
          div.filters
            span.labelFilter #[translate Search]:
              div.filter.ml-2.mr-4
                input.filterBySearch(type="text" v-model="search")
                button.filterBySearchButton.clean(@click="cleanBySearch" v-if="$route.query.search || search")
                  img(:src="img.clean")
                button.filterBySearchButton(@click="filterBySearch")
                  img(:src="img.search")
            span.downloadExcel.mr-5(@click="downloadExcelCustom()")
              img(:src="imgCommons.download" width="10")

            span.labelFilter #[translate Filter reviews]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="opinionsTypes" label-class="text-capitalize-first-letter" :itemsChecked="opinionsTypesSelectedMaped" @checked="onCheckOpinionsTypes" id-key="id" label-key="name")
            span.labelFilter #[translate Categories]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="opinionsCategories" label-class="text-capitalize-first-letter" :itemsChecked="opinionsCategoriesSelected" @checked="onCheckOpinionsCategories" id-key="id" label-key="name")
            span.labelFilter #[translate Motivo]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="reasonOptions" label-class="text-capitalize-first-letter" :itemsChecked="selectedReasonOptions" @checked="onCheckReasons" id-key="id" label-key="name")

      v-row.contentOutPadding.mt-0.px-3#boxTableComments
        table-opinions(:comments="comments" :loading="loading" :notResponse="true" style="width:100%" @linkFilterCategory="linkFilterByCategory" @setOrder="setOrder" :isDelivery="true")
</template>

<script>
import SrvFeedback from '@/services/delivery-feedback.js'
import * as types from '@/store/mutation-types';
import commonMixins from "@/mixins/common.js";
import FilterTreeMenu from "@/components/filters/filterTreeMenu";
import TableOpinions from "@/components/global/TableOpinions"

export default {
  mixins: [commonMixins],
  components: {TableOpinions, FilterTreeMenu}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  data() {
    return {
      showImage: false,
      picture: '',
      //search: null,
      img: {
        sort: require('@/assets/img/mini_arrow_shack_disable.svg'),
        sortActive: require('@/assets/img/mini_arrow_shack_active.svg'),
        replyNo: require('@/assets/img/ico-reply-no.svg'),
        replyYes: require('@/assets/img/ico-reply-yes.svg'),
        search: require('@/assets/img/buscar.svg'),
        clean: require('@/assets/img/borrar.svg')
      },
      ordering: '-source_date',
      comments: [], // COLECCION DE COMENTARIOS
      opinionsTypes: [ // ELEMENTOS FILTRO POR TIPO DE COMENTARIO
        {id: 'with_text', name: this.$gettext('With comments')},
        {id: 'without_text', name: this.$gettext('Without comments')},
        {id: 'positives', name: this.$gettext('Positives')},
        {id: 'neutrals', name: this.$gettext('Neutral')},
        {id: 'negatives', name: this.$gettext('Negatives')}
        /*{id: 'with_reply', name: this.$gettext('With reply')},
        {id: 'without_reply', name: this.$gettext('Without reply')}*/
      ],
      opinionsTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO

      options: {}, // OPCIONES DE V-TABLE
      loading: true, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
      loadingtext: this.$gettext('Loading comments'),
      totalWidth: 0,
      nextScrollUrl: null,
      nextCall: false,
      opinionCounts: '',
      center: undefined
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  async created() {
    await this.$store.dispatch('getReasons')
    if (['group'].includes(this.typeNode)) {
      this.$nextTick(() => {
        this.$router.push({name: 'GlobalSummary', params: {paramCOD: this.$route.params.paramCOD}, query: this.$route.query})
      })
    } else {
      this.opinionsTypesSelected = this.$store.getters.getOpinionsTypesSelected
      if (this.$store.getters.getNode > 0) {
        this.getOpinions()
      }
      if (this.comments.length === 0) {
        if (this.$store.getters.getComments.length > 0) {
          this.comments = this.$store.getters.getComments.results
          this.loading = false
        }
      }
    }
  },
  mounted() {
    this.scroll()
    window.openTripAdvisor = this.openTripAdvisor
    window.closeTripAdvisor = this.closeTripAdvisor
    window.savingTripAdvisor = this.savingTripAdvisor
    window.commitTripAdvisor = this.commitTripAdvisor
    window.showErrorTripAdvisor = this.showErrorTripAdvisor
  },
  watch: {
    filterApply: function () {
      this.nextScrollUrl = null
      this.getOpinions()
    },
    ordering: function () {
      this.nextScrollUrl = null
      this.getOpinions()
    },
    totalWidth: function () {
      return this.totalWidth
    },
    comments(comments) {
      const data = {}
      comments.forEach(item => data[item.id] = item)
      window.data = data
    }
  },
  computed: {
    search: {
      get() {
        return this.$store.getters.getOpinionsSearch
      },
      set(val) {
        this.$store.commit(types.FEEDBACK_OPINIONS_SEARCH, val)
      }
    },
    userCanReply() {
      if (this.mocking) {
        return true
      } else {
        return JSON.parse(window.localStorage.getItem('_user_info')).can_reply_reviews
      }
    },
    opinionsCategories() {
      let categoriesCrud = []
      if (this.$store.getters.getCategories['delivery']) {
        categoriesCrud = this.$store.getters.getCategories['delivery'].categories
      }

      return categoriesCrud
    },
    reasonOptions() {
      return this.$store.getters.getReasons
    },
    selectedReasonOptions() {
      return this.$store.getters.getReasonsSelected.map(c => c.value)
    },
    opinionsCategoriesSelected() {
      return this.$store.getters.getOpinionsCategoriesSelected.map(c => c.value)
    },
    opinionsTypesSelectedMaped() {
      return this.opinionsTypesSelected.map(c => c.value)
    },
    headerHeight() {
      return this.$store.getters.getTopSectionHeight - 2
    }
  },
  methods: {
    cleanBySearch() {
      this.$store.commit(types.FEEDBACK_OPINIONS_SEARCH, null)
      if (this.$route.query.search) {
        this.nextScrollUrl = null
        this.updateUrl()
        this.getOpinions()
      }
    },
    filterBySearch() {
      this.nextScrollUrl = null
      this.updateUrl()
      this.getOpinions()
    },
    setOrder(val) {
      this.ordering = val
    },
    showErrorTripAdvisor(id) {
      this.$set(window.data[id], 'error', true)
    },
    async commitTripAdvisor(id) {
      await this.sleep(1000);
      if (!window.data[id].reply) {
        this.$set(window.data[id], 'reply', {})
      }
      this.$set(window.data[id].reply, 'text', window.data[id].unsavedReply)
      this.$set(window.data[id], 'unsavedReply', undefined)
    },
    savingTripAdvisor(id, status) {
      this.$set(window.data[id], 'saving', status)
    },
    openTripAdvisor() {
      this.comments.forEach(item => {
        if (item.site_info.code === 'TRI' && item.allow_reply && this.userCanReply) {
          item.showByPlugin = true
        }

      })
    },
    closeTripAdvisor() {
      this.comments.forEach(item => {
        if (item.site_info.code === 'TRI') {
          item.showReply = false
        }
      })
    },
    autoSize(inputEvent) {
      inputEvent.target.style.height = '';
      inputEvent.target.style.height = (inputEvent.target.scrollHeight + 2) + 'px'
    },
    downloadExcelCustom() {
      let params = {ordering: this.ordering}
      this.downloadExcel(params, this.opinionCounts)
    },
    goItemNextLevelCustom(item) {
      let itemCustom = {}
      itemCustom.id = item.center.id
      itemCustom.name = item.center.name
      itemCustom.level = 9
      this.goItemNextLevel(itemCustom, true)
    },
    getTextOpinion(item) {
      let text = ''
      if (item.text !== null && item.text !== '') {
        text += item.text
      }
      if (item.text === null) {
        text = this.$gettext('The user has not written a review.')
      }
      return text
    },
    setOrderAsc(val) {
      this.ordering = '-' + val
    },
    setOrderDesc(val) {
      this.ordering = val
    },
    showDownComment: function (item) {
      if (item.text && item.text.length > 80) {
        return true;
      }
      return true
    },
    // SE PULSA PARA DESPLEGAR COMENTARIO COMPLETO
    // atributo "comentariocompleto mapeado en la petición al api"
    mostrarComentario: function (item) {
      item.comentariocompleto = item.comentariocompleto !== true;
    },
    linkFilterByCategory(category) {
      this.onCheckOpinionsCategories([category])
    },
    // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
    onCheckOpinionsTypes(val) {
      this.opinionsTypesSelected = val
      this.$store.commit(types.FEEDBACK_FILTER_OPINIONS_TYPES_SELECTED, val)
      this.nextScrollUrl = null
      this.getOpinions()
    },
    onCheckOpinionsCategories(val) {
      this.$store.commit(types.FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED, val)
      this.nextScrollUrl = null
      this.getOpinions()
    },
    onCheckReasons(val) {
      this.$store.commit(types.REASONS_SELECTED, val)
      this.nextScrollUrl = null
      this.getOpinions()
    },
    // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
    getOpinions: function () {
      if (!this.nextScrollUrl) {
        SrvFeedback.getReviews(this.ordering)
          .then(res => {
            if (res.status === 200) {
              let custom = res.data.results.map((obj, index) => ({
                ...obj,
                ['comentariocompleto']: false,
                ['showReply']: false,
                ['showByPlugin']: false,
                ['unsavedReply']: '',
                ['idGenerate']: obj.id + '-' + index
              }))
              if (this.mocking) {
                if (!custom[0].reply) {
                  custom[0].reply = {}
                }
                custom[0].reply.text = ""
                custom[0].link_to_publication_img = "https://media-cdn.tripadvisor.com/media/photo-s/14/c7/5e/e4/img-20180924-130725-largejpg.jpg"
                custom[0].allow_reply = true
              }
              this.comments = custom;
              this.nextScrollUrl = res.data.next
              this.opinionCounts = res.data.count
              this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
              this.loading = false
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      } else {
        SrvFeedback.getReviewsNext(this.nextScrollUrl)
          .then(res => {
            if (res.status === 200) {
              let ind = this.comments.length
              let custom = res.data.results.map((obj, index) => ({
                ...obj,
                ['comentariocompleto']: false,
                ['showReply']: false,
                ['unsavedReply']: '',
                ['idGenerate']: obj.id + '-' + (index + ind)
              }))
              custom.forEach(element => {
                this.comments.push(element)
              });
              this.nextScrollUrl = res.data.next
              this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
              this.nextCall = false
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      }
    },
    getOpinionsCount() {
      SrvFeedback.getReviewsCount()
        .then(res => {
          if (res.status === 200) {
            this.opinionCounts = res.data
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })

    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'DeliveryReviewsReviews') {
          var body = document.body,
            html = document.documentElement;
          var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
          height = parseInt(height, 10) - 1
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true
            this.getOpinions();
          }
        }
      }
    },

  },
}
</script>

<style>
.main {
  position: sticky;
  z-index: 2;
  background: #fff;
}

.ligthbox-header {
  position: absolute;
  width: 100%;
  z-index: 999;
  background: transparent !important;
}

.border-20 .v-image {
  border-radius: 5%;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.filterBySearch {
  vertical-align: middle;
  font-size: 0.75rem;
  border: 1px solid #D7DAE2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
  background: white;
  color: #1976d2;
  height: 34px;
  padding-left: 16px;
}

.filterBySearchButton {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #D7DAE2;
  border-left: 0;
  height: 34px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;

  &.clean {
    border-right: 1px solid #D7DAE2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    width: 14px;
    margin-top: 5px;
  }
}

.respuesta {
  padding: 1em;
  border-top: solid #E5E6E7 2px;
}

.bgRed {
  background-color: #D61A23;
}

.bgOrange {
  background-color: #FCAE19;
}

.bgGreen {
  background-color: #89C445;
}

.boxOrder {
  float: right;
  margin-top: 4px;

  .btnOrder {
    display: block;
    line-height: 0;
    cursor: pointer;

  }
}

.boxNoteAndName {
  .boxDown {
    width: 10%;
    text-align: center;

    .down {
      margin-top: 5px;
      display: inline-block;
      transition: all ease 0.3s;
      cursor: pointer;

      &.rotate {
        transform: rotate(90deg)
      }
    }
  }

  .boxNote {
    width: 79%;
    margin-left: 10%;

    span {
      color: #fff;
      border-radius: 5px;
      width: 38px;
      padding: 4px 0;
      display: inline-block;
      text-align: center;

      &.withOutNote {
        background-color: #eaeaea;
        color: #000;
      }
    }
  }

  .boxDown,
  .boxNote {
    display: inline-block;
    vertical-align: middle;
  }

  .boxName {
    width: 78%;
    margin-left: 20%;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #52575A;
    margin-top: 8px;
  }
}


.comentario {
  transition: all ease 0.3s;
  font-size: 0.813rem;
  margin-top: -4px;
  padding-left: 1em;

  &.comentarioRecortado {
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    min-width: 0;
  }
}

.tipo {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 3px;
  margin: 0 auto;
}

.filters {
  padding: 15px 0;
}

.block-count {
  font-size: 1.125rem;
}

.block-head {
  border-bottom: 1px solid #DFE3E6;
}
</style>
